<script>
export default {
  data() {
    return {
      categoryCoursesName: 'courses',
      parameterTargetGroupId: '5f8db0540e35b60017eaa57a',
      parameterTypeId: '5f8dafd30e35b60017eaa579',
    }
  },
}
</script>
