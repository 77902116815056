import { render, staticRenderFns } from "./CourseBox.vue?vue&type=template&id=3e85dd7c&"
import script from "./CourseBox.js?vue&type=script&lang=js&"
export * from "./CourseBox.js?vue&type=script&lang=js&"
import style0 from "./CourseBox.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CourseBox.lang.cs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fnobilis%2Fcomponents%2Fproduct%2FCourseBox%2FCourseBox.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports