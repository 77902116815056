<template>
  <PageHomepage
    :header-configuration="headerConfiguration"
    :homepage-configuration="homepageConfiguration"/>
</template>

<script>
import AppRouteMixin from '~/mixins/AppRouteMixin'
import PageHomepage from '@theme/components/content/PageHomepage'

const PAGE = {
  name: 'RouteHomepage',
  middleware: [
    ({ store }) => {
      store.commit('locale/SET_ALTERNATIVE_PATHS', {
        cs: '/',
        sk: '/',
      })
    },
  ],
  nuxtI18n: {
    paths: {
      cs: '/',
    },
  },
  components: {
    PageHomepage,
  },
  async asyncData({ app, store, query, redirect, req }) {
    // Handle duplicates
    if (query.p === '0') {
      delete query.p
      redirect(301, req.path, query)
      return
    }
    store.commit('locale/SET_CANONICALS', {
      nuxtI18nPaths: PAGE.nuxtI18n.paths,
    })

    const resolvedData = {
      headerConfiguration: await store.dispatch('article/LOAD_HEADER_CONFIGURATION'),
      homepageConfiguration: await store.dispatch('homepage/LOAD_HOMEPAGE_CONFIGURATION'),
    }
    await app.apolloProvider.defaultClient.cache.reset()

    return resolvedData
  },
  mixins: [AppRouteMixin],
}
export default PAGE
</script>
