import AddToCart from '@theme/components/cart/AddToCart'
import AppImage from '@theme/components/atom/AppImage'
import AppNumber from '@theme/components/atom/AppNumber'
import AppTitle from '@theme/components/atom/AppTitle'
import Badge from '@theme/components/atom/Badge'
import BusinessUserMixin from '~/mixins/BusinessUserMixin'
import CoursesMixin from '~/mixins/CoursesMixin'
import Dropdown from '@theme/components/utils/Dropdown'
import DropdownContent from '@theme/components/utils/DropdownContent'
import HeadingLevel from '~/mixins/HeadingLevel'
import ProductAvailability from '@theme/components/product/ProductAvailability'
import ProductBoxMixin from '~/mixins/ProductBoxMixin'
import ProductCreditsMixin from '~/mixins/ProductCreditsMixin'
import ProductImpressionMixin from '~/mixins/ProductImpressionMixin'
import ProductMixin from '~/mixins/ProductMixin'
import ScrollToEventMixin from '~/mixins/ScrollToEventMixin'
import Tag from '@theme/components/atom/Tag'
import _ from 'lodash'
import eventDefinitions from '~/events/eventDefinitions'

export default {
  mixins: [
    BusinessUserMixin,
    CoursesMixin,
    HeadingLevel,
    ProductCreditsMixin,
    ProductBoxMixin,
    ProductImpressionMixin,
    ProductMixin,
    ScrollToEventMixin,
  ],
  components: {
    AddToCart,
    AppImage,
    AppNumber,
    AppTitle,
    Badge,
    Dropdown,
    DropdownContent,
    ProductAvailability,
    Tag,
  },
  data() {
    return {
      quantity: 1,
    }
  },
  created() {
    if (this.activeVariantId === this.product.defaultVariantId) {
      this.activeVariantId = this.variantsOrdered[0].id
    }
  },
  mounted() {
    this.$on('scrolledTo', () => {
      this.$eventBus.$emit(eventDefinitions.PRODUCT.IMPRESSION, {
        product: this.product,
      })
    })
  },
  computed: {
    activeVariant() {
      return this.product.hasVariants
        ? _.find(this.product.variants, v => v.id === this.activeVariantId) || this.product.variants[0]
        : this.product.variants[0]
    },
    percentageDiscount() {
      return Math.round(this.product.prices.percentageDiscount)
    },
    targetGroup() {
      return this.$options.filters.prepositions(
        this.$t('CourseBox.targetGroup', { targetGroup: this.parameterValue(this.parameterTargetGroupId) }),
      )
    },
    type() {
      return this.$options.filters.prepositions(
        this.$t('CourseBox.type', { type: this.parameterValue(this.parameterTypeId) }),
      )
    },
    variantsOrdered() {
      const sortedVariants = this.product.variants.map(variant => variant)
      const variantDates = sortedVariants.reduce((acc, variant) => {
        return {
          ...acc,
          [variant.id]:
            variant.content &&
            variant.content.course_date_start &&
            variant.content.course_date_start[this.$i18n.locale],
        }
      }, {})
      sortedVariants.sort((a, b) => {
        if (!variantDates[a.id]) {
          return -1
        }
        if (!variantDates[b.id]) {
          return 1
        }
        return variantDates[a.id].localeCompare(variantDates[b.id])
      })
      return sortedVariants
    },
  },
  methods: {
    clicked() {
      this.$eventBus.$emit(eventDefinitions.PRODUCT.CLICK, { product: this.product })
    },
  },
}
