import { get } from 'lodash'
import { hydrateWhenVisible } from 'vue-lazy-hydration'
import AppImage from '@theme/components/atom/AppImage'
import AppTitle from '@theme/components/atom/AppTitle'
import ArticleBox from '@theme/components/content/ArticleBox'
import ContentHeader from '@theme/components/content/ContentHeader'
import CourseBox from '~/themes/nobilis/components/product/CourseBox'
import FileMixin from '~/mixins/FileMixin'
import HeaderConfigurationMixin from '~/mixins/HeaderConfigurationMixin'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import LayoutBase from '@theme/components/shop/LayoutBase'
import SeoMixin from '~/mixins/SeoMixin'

export default {
  components: {
    AppImage,
    AppTitle,
    ArticleBox: hydrateWhenVisible(ArticleBox),
    CourseBox: hydrateWhenVisible(CourseBox),
    ContentHeader: hydrateWhenVisible(ContentHeader),
    LayoutBase,
  },
  mixins: [FileMixin, HeaderConfigurationMixin, ImageUrlMixin, SeoMixin],
  props: {
    headerConfiguration: null,
    homepageConfiguration: [Object, Boolean],
  },
  data() {
    return {
      breadcrumbs: [],
      sidebarCourse: null,
    }
  },
  head() {
    return this.getSeoHead()
  },
  async mounted() {
    const course = this.getHomepageSidebarCourse
    let cmsCourse
    if (course && course.id) {
      cmsCourse = await this.$store.dispatch('product/LOAD_PRODUCTS', { ids: [course.id] })
      if (cmsCourse && cmsCourse.length > 0) {
        await this.$store.dispatch('product/FETCH_PARAMETERS', { product: cmsCourse[0] })
        this.sidebarCourse = cmsCourse[0]
      }
    }
  },
  computed: {
    getHeaderData() {
      return {
        image: {
          alt: get(this.headerConfiguration, 'image.[0].alt') || '',
          path: get(this.headerConfiguration, 'image[0].path') || '',
        },
        title: get(this.headerConfiguration, 'title'),
      }
    },
    getHomapegeArticles() {
      return get(this.homepageConfiguration, 'articles') || []
    },
    getHomepageTags() {
      return get(this.homepageConfiguration, 'sidebar_tags') || false
    },
    getHomepageTagCards() {
      return get(this.homepageConfiguration, 'tag_cards') || []
    },
    getHomepageMagazine() {
      const magazineImage =
        get(this.homepageConfiguration, 'sidebar_magazine[0].image[1]') ||
        get(this.homepageConfiguration, 'sidebar_magazine[0].image[0]')
      return get(this.homepageConfiguration, 'sidebar_magazine[0]')
        ? {
            articles: get(this.homepageConfiguration, 'sidebar_magazine[0].related_articles') || [],
            image: magazineImage
              ? {
                  alt: get(magazineImage, 'alt'),
                  path: get(magazineImage, 'path'),
                }
              : false,
            magazine_file: get(this.homepageConfiguration, 'sidebar_magazine[0].magazine_file[0]')
              ? {
                  path: get(this.homepageConfiguration, 'sidebar_magazine[0].magazine_file[0].path'),
                  title: get(this.homepageConfiguration, 'sidebar_magazine[0].magazine_file[0].title'),
                }
              : false,
            perex: get(this.homepageConfiguration, 'sidebar_magazine[0].perex'),
            title: get(this.homepageConfiguration, 'sidebar_magazine[0].title'),
          }
        : false
    },
    getHomepageMainArticle() {
      const articleImage =
        get(this.homepageConfiguration, 'articles[0].image[1]') ||
        get(this.homepageConfiguration, 'articles[0].image[0]')
      return get(this.homepageConfiguration, 'articles[0]')
        ? {
            categoryTitle: get(this.homepageConfiguration, 'articles[0].article_category_reference[0].entity.title'),
            image: articleImage
              ? {
                  path: get(articleImage, 'path'),
                  alt: get(articleImage, 'alt'),
                }
              : false,
            title: get(this.homepageConfiguration, 'articles[0].title'),
            perex: get(this.homepageConfiguration, 'articles[0].perex'),
            slug: get(this.homepageConfiguration, 'articles[0].page_meta.slug'),
          }
        : false
    },
    getHomepageSidebarArticles() {
      return get(this.homepageConfiguration, 'sidebar_articles') || false
    },
    getHomepageSidebarCourse() {
      return get(this.homepageConfiguration, 'sidebar_course[0].data') || false
    },
    showHeader() {
      return this.$themeSettings.components.PageArticleList.header.enable && this.showHeaderImage
    },
  },
}
